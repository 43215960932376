import React from 'react'
import Gallery from '../../blocks/Gallery/Gallery'

import Img1 from '../../../assets/projects/exterior/Img1.jpg'
import Img2 from '../../../assets/projects/exterior/Img2.jpg'
import Img3 from '../../../assets/projects/exterior/Img3.jpg'
import Img4 from '../../../assets/projects/exterior/Img4.jpg'
import Img5 from '../../../assets/projects/exterior/Img5.jpg'
import Img6 from '../../../assets/projects/exterior/Img6.jpg'
import Img7 from '../../../assets/projects/exterior/Img7.jpg'
import Img8 from '../../../assets/projects/exterior/Img8.jpg'
import Img9 from '../../../assets/projects/exterior/Img9.jpg'
import Img10 from '../../../assets/projects/exterior/Img10.jpg'
import Img11 from '../../../assets/projects/exterior/Img11.jpg'

const Exterior = () => {

  const Galleries = [
    {
      img: Img1,
      title: 'Exterior Design Image 1',
    },
    {
      img: Img2,
      title: 'Exterior Design Image 2',
    },
    {
      img: Img3,
      title: 'Exterior Design Image 3',
    },
    {
      img: Img4,
      title: 'Exterior Design Image 4',
    },
    {
      img: Img5,
      title: 'Exterior Design Image 5',
    },
    {
      img: Img6,
      title: 'Exterior Design Image 6',
    },
    {
      img: Img7,
      title: 'Exterior Design Image 7',
    },
    {
      img: Img8,
      title: 'Exterior Design Image 8',
    },
    {
      img: Img9,
      title: 'Exterior Design Image 9',
    },
    {
      img: Img10,
      title: 'Exterior Design Image 10',
    },
    {
      img: Img11,
      title: 'Exterior Design Image 11',
    }
  ]

  return (
    <div>
        <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Exterior Design Projects</h2>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Browse through our portfolio of exterior design projects, where we have transformed spaces into visually captivating and functional environments.</p>
        <Gallery Images={Galleries}/>
    </div>
  )
}

export default Exterior